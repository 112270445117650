import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useMemo,
  useImperativeHandle,
  useRef,
  forwardRef,
  useEffect,
} from "react";

import { useStack } from "../utils/hooks/useStack";

export const PopOverContext = createContext(null);

function PopOver({ children }, popOverRef) {
  const beforeChildrenStack = useStack();
  // const headerStack = useStack();

  const [isShown, setIsShown] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const childrens = useRef([]);

  const hidePopOver = useCallback(
    (popStack = false) => {
      setIsShown(false);
      setTimeout(() => {
        setTop(0);
        setLeft(0);
        if (childrens.current.length > 0) {
          childrens.current.forEach((popOverChild) => popOverChild.hide());
        }
        // if (popStack) {
        //   stack.pop();
        //   headerStack.pop();
        // }
      }, 200);
    },
    [childrens]
  );

  const showPopOver = useCallback(
    (props) => {
      if (!isShown) {
        const { topPosition, leftPosition } = props;
        setTop(topPosition);
        setLeft(leftPosition);
        setIsShown(true);
      } else {
        hidePopOver();
      }
    },
    [isShown, hidePopOver]
  );

  // const handleGoBack = useCallback(() => {
  //   stack.pop();
  //   headerStack.pop();
  // }, [stack, headerStack]);

  // const registerChildComponent = useCallback(({ childComponent, headerText }) => {
  //   stack.push(childComponent);
  //   headerStack.push(headerText);
  // }, [stack, headerStack]);

  const registerChildren = useCallback(
    ({ name, headerText, ref, show, hide, onGoBack }) => {
      childrens.current.push({
        name,
        headerText,
        ref,
        isShown: false,
        show,
        hide,
        onGoBack,
      });
    },
    []
  );

  const unregisterChildren = useCallback(
    (childrenName) => {
      const childIndex = childrens.current.findIndex(child => child.name === childrenName)

      if (childIndex > -1) {
        childrens.current.splice(childIndex, 1)
      }
    },
    [childrens]
  )

  const cleanBeforeChildrens = useCallback(() => {
    beforeChildrenStack.items.forEach((beforeChildren) => {
      if (beforeChildren.onGoBack) {
        beforeChildren.onGoBack();
      }
    });

    beforeChildrenStack.empty();
  }, [beforeChildrenStack]);

  const showChildByName = useCallback(
    (childName, emptyBeforeStack = false) => {
      if (emptyBeforeStack) {
        cleanBeforeChildrens();
      }

      childrens.current.forEach((popOverChild) => {
        if (popOverChild.isShown && popOverChild.name !== childName) {
          popOverChild.hide();
          popOverChild.isShown = false;
          if (!emptyBeforeStack) {
            beforeChildrenStack.push(popOverChild);
          }
          // setLastStack(popOverChild);
        }

        if (popOverChild.name === childName) {
          popOverChild.isShown = true;
          popOverChild.show();
        }
      });
    },
    [childrens, beforeChildrenStack, cleanBeforeChildrens]
  );

  const goBackToBeforeChildren = useCallback(() => {
    if (beforeChildrenStack.items.length > 0) {
      const beforeChildrenStackTop = beforeChildrenStack.top();

      const beforeChildren = childrens.current.find(
        (popOverChildren) =>
          popOverChildren.name === beforeChildrenStackTop.name
      );

      if (beforeChildren) {
        const currentShownChildren = childrens.current.find(
          (popOverChild) => popOverChild.isShown
        );

        if (currentShownChildren.onGoBack) {
          currentShownChildren.onGoBack();
        }

        showChildByName(beforeChildren.name);
        beforeChildrenStack.pop();
      }
    }
  }, [beforeChildrenStack, childrens, showChildByName]);

  const value = useMemo(
    () => ({
      isShown,
      currentShownChildren: childrens.current.find(
        (popOverChild) => popOverChild.isShown
      ),
      top,
      left,
      childrens: childrens.current,
      hasBeforeChildren: beforeChildrenStack.items.length > 0,
      registerChildren,
      unregisterChildren,
      showChildByName,
      handleGoBackToBeforeChildren: goBackToBeforeChildren,
      showPopOver,
      hidePopOver,
    }),
    [
      isShown,
      top,
      left,
      childrens,
      beforeChildrenStack,
      registerChildren,
      unregisterChildren,
      showChildByName,
      goBackToBeforeChildren,
      showPopOver,
      hidePopOver,
    ]
  );

  useImperativeHandle(popOverRef, () => ({
    handleShow: showPopOver,
    handleShowChildByName: showChildByName,
  }));

  useEffect(() => {
    if (childrens.current.length > 0) {
      childrens.current.forEach((child) => {
        child.hide();
      });
    }
  }, [childrens]);

  return (
    <PopOverContext.Provider value={value}>{children}</PopOverContext.Provider>
  );
}

export const PopOverProvider = forwardRef(PopOver);

export function usePopOver() {
  return useContext(PopOverContext);
}

export function usePopOverChildren() {
  const { registerChildren, unregisterChildren } = useContext(PopOverContext);

  return { registerChildren, unregisterChildren };
}

/*
  create usePopOverChild() like unform does with useField, to register the childrens on the popover
  this childrens will has a 'name' and ref to the component to show.
  e.g: function handleEditLabel() { popOverRef.current.showChild('EditLabels'); }
  function showChild(childName) {
    const child = childrens.find(popOverChild => popOverChild.name === childName);

    if (child) {
      return child.ref.show();
    }

    // before this, hide the current child that is shown on popover e.g: current.ref.hide();
  }
*/
