import styled, { css } from "styled-components";

export const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: 0;
  border: 0;
  outline: 0;
  border-radius: 3px;
  padding: 0 5px;
  cursor: pointer;
  transition: background 0.3s;

  :focus {
    border: 0;
    outline: 0;
  }

  ${({ withHover }) =>
    withHover &&
    css`
      :hover {
        background: ${(props) => props.theme.colors.background.primary};
      }
    `};

  svg {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;
