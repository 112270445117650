import { createGlobalStyle } from "styled-components";
import { lighten } from "polished";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    // Scrollbar Styling

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: inherit;
    }
    
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => lighten(0.4, theme.colors.text.secundary)};
      border-radius: 4px; 
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => lighten(0.3, theme.colors.text.secundary)}; 
    }
  }

  html, body, #root {
      height: 100%;
  }

  body {
      font: 400 16px Roboto, sans-serif;
      background: ${(props) => props.theme.colors.background.primary};
      -webkit-font-smoothing: antialiased;
  }

  input, button, textarea {
      font: 400 18px Roboto, sans-serif;
  }

  /* Remove input number arrows */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* .input-wrong {
    animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    border: 1px solid #eb3f3f;
  }

  .input-wrong::placeholder {
      color: #eb3f3f;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  } */

  @media print {
    body, html, div#root
    {
      overflow: visible !important;
    }
  }
`;
