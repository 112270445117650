import React, { useState, useCallback, useRef } from "react";
import { useSpring } from "react-spring";
import { FiChevronDown } from "react-icons/fi";

import { Container, DropdownOptions } from "./styles";

function NavBarDropdown({
  active,
  togglerComponent: TogglerComponent,
  children,
}) {
  const [toggle, setToggle] = useState(false);
  const collapseRef = useRef(null);

  const collapse = useSpring({
    maxHeight: toggle ? 400 : 0,
    opacity: toggle ? 1 : 0,
    marginTop: toggle ? 12 : 0,
  });

  const handleToggle = useCallback(() => {
    setToggle((oldToggle) => !oldToggle);
  }, []);

  return (
    <Container className="dropdown">
      <button
        type="button"
        className={`dropdown-toggler ${active ? "active-nav-link" : ""} ${toggle ? "opened" : ""
          }`}
        onClick={handleToggle}
      >
        <TogglerComponent />
        <FiChevronDown size={20} />
      </button>
      <DropdownOptions
        className="dropdown-options"
        style={collapse}
        ref={collapseRef}
      >
        {toggle && children}
      </DropdownOptions>
    </Container>
  );
}

export default NavBarDropdown;
