import React, { forwardRef } from "react";

import { StyledInput } from "./styles";

function ThemeDefault({ ...rest }) {
  return <StyledInput {...rest} />;
}

export default ThemeDefault;

export const ForwardedRefInput = forwardRef(({ ...rest }, ref) => {
  return <StyledInput ref={ref} {...rest} />;
});
