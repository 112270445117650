import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  max-height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;

  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);

  div.default-modal-body {
    width: 768px;
    max-height: calc(100vh - 20px);

    overflow-y: auto;
    overflow-x: hidden;

    background: ${({ theme }) => theme.colors.background.secundary};
    border-radius: 8px;
    padding: 8px;

    animation-name: animatetop;
    animation-duration: 0.2s;
  }

  div.default-modal-body header.default-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div.default-modal-body div.content {
    padding: 20px;
    overflow-x: hidden;
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
`;

export const Title = styled.strong`
  font-size: 22px;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: ${({ editable }) => (editable ? "pointer" : "default")};
`;
