export default function searchItemById(id, array) {
  let item = {};
  let index = -1;

  array.forEach((object, i) => {
    if (object.id === id) {
      item = array[i];
      index = i;
    }
  });
  return { item, index };
}
