import styled from "styled-components";
import { animated } from "react-spring";
import { shade } from "polished";

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  img.user-profile-photo {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: opacity 0.2s ease;

    :hover {
      opacity: 0.8;
    }
  }
`;

export const UserPopupBox = styled(animated.div)`
  position: absolute;
  top: 4rem;
  left: -12rem;
  z-index: 5;

  width: 15rem;

  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.background.primary};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
`;

export const PopupArrow = styled.div`
  position: absolute;
  top: -0.5rem;
  right: 1rem;
  z-index: -1;

  width: 1rem;
  height: 1rem;
  transform: rotate(-135deg);

  background: ${({ theme }) => theme.colors.background.primary};
  box-shadow: 0.75rem 0.75rem 1rem rgba(0, 0, 0, 0.2);
`;

export const PopupMainContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  a {
    display: flex;
    align-items: flex-end;
    position: relative;

    width: 100%;
    padding: 1rem;

    text-decoration: none;
    font-size: 1rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.text.secundary};

    transition: background 0.2s ease;

    svg {
      margin-right: 1rem;
    }

    :hover {
      background: ${({ theme }) => shade(0.05, theme.colors.background.primary)};
    }
  }

  a.has-notifications {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const LogoutButton = styled.button`
  display: flex;
  align-items: flex-end;
  position: relative;

  padding: 1rem;
  border: none;
  outline: 0;
  background: transparent;

  font-size: 1rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.text.secundary};
  cursor: pointer;

  transition: background 0.2s ease;

  svg {
    margin-right: 1rem;
  }

  :hover {
    background: ${({ theme }) => shade(0.05, theme.colors.background.primary)};
  }

  ::before {
    position: absolute;
    top: 0;
    left: 0;

    content: "";

    width: 100%;
    height: 0.15rem;

    background: ${({ theme }) => shade(0.1, theme.colors.background.primary)};
  }
`;

export const HasNotificationsNotSeenBadge = styled.span`
  position: absolute;
  top: ${({ calcTop }) => `calc(${calcTop})`};
  left: ${({ calcLeft }) => `calc(${calcLeft})`};

  width: ${({ size }) => `${size}rem`};
  height: ${({ size }) => `${size}rem`};

  border-radius: ${({ size }) => `${size / 2}rem`};
  background: ${({ theme }) => theme.colors.primary.dark};
`;
