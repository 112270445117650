import React, {
  //  Suspense
} from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import { RefreshOnRender } from "./components/RefreshOnRender";

// const Login = React.lazy(() => import("./pages/Login"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const Main = React.lazy(() => import("./pages/Main"));
const Okrs = React.lazy(() => import("./pages/Okrs"));
const OkrsList = React.lazy(() => import("./pages/OkrsList"));
const OkrDetails = React.lazy(() => import("./pages/OkrDetails"));
const Monitoring = React.lazy(() => import("./pages/Monitoring"));
const CheckIn = React.lazy(() => import("./pages/CheckIn"));
const CheckInList = React.lazy(() => import("./pages/CheckInList"));
const CheckInDetails = React.lazy(() => import("./pages/CheckInDetails"));
const ManagementLinks = React.lazy(() => import("./pages/ManagementLinks"));
const OkrsEvolution = React.lazy(() => import("./pages/OkrsEvolution"));
const OkrsCanvas = React.lazy(() => import("./pages/OkrsCanvas"));
const RegisterCompany = React.lazy(() => import("./pages/RegisterCompany"));
const RegisterDirectroship = React.lazy(() => import("./pages/RegisterDirectroship"));
const RegisterDepartment = React.lazy(() => import("./pages/RegisterDepartment"));
const RegisterTeam = React.lazy(() => import("./pages/RegisterTeam"));
const RegisterUser = React.lazy(() => import("./pages/RegisterUser"));
const Flow = React.lazy(() => import("./pages/Flow"));
const FlowBoard = React.lazy(() => import("./pages/FlowBoard"));
const BoardInvitation = React.lazy(() => import("./pages/BoardInvitation"));
const Profile = React.lazy(() => import("./pages/Profile"));
const ManagementCompanies = React.lazy(() => import("./pages/ManagementCompanies"));
const ManagementDepartments = React.lazy(() => import("./pages/ManagementDepartments"));
const ManagementUsers = React.lazy(() => import("./pages/ManagementUsers"));
const ManagementOkrsPermissions = React.lazy(() => import("./pages/ManagementOkrsPermissions"));
const ManagementOkrPermissions = React.lazy(() => import("./pages/ManagementOkrPermissions"));
const ManagementInitiatives = React.lazy(() => import("./pages/ManagementInitiatives"));
const InitiativesMonitoring = React.lazy(() => import("./pages/InitiativesMonitoring"));
const Cycles = React.lazy(() => import("./pages/Cycles"));
const CloseCycle = React.lazy(() => import("./pages/CloseCycle"));
const ResetPassowrdLogged = React.lazy(() => import("./pages/ResetPassowrdLogged"));
const CompanyReports = React.lazy(() => import("./pages/CompanyReports"));
const LevelsNicknames = React.lazy(() => import("./pages/LevelsNicknames"));
const UserSettings = React.lazy(() => import("./pages/UserSettings"));


export default function Routes() {
  return (
    <BrowserRouter>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Switch>
        <Route path="/" exact component={RefreshOnRender} />
        <Route path="/sign-in" exact component={RefreshOnRender} />
        {/* <Route path="/login" exact component={Login} /> */}
        <Route path="/reset-password" component={ResetPassword} />
        <PrivateRoute path="/home" component={Main} />
        <PrivateRoute path="/okrs" exact component={Okrs} />
        <PrivateRoute path="/okrs/list" exact component={OkrsList} />
        {/* <PrivateRoute path="/okrs/reports" exact component={OkrsReports} /> */}
        <PrivateRoute path="/okrs/monitoring" exact component={Monitoring} />
        <PrivateRoute path="/okrs/checkins" exact component={CheckIn} />
        <PrivateRoute
          path="/okrs/checkins/company-all"
          exact
          component={CheckInList}
        />
        <PrivateRoute
          path="/okrs/checkins/:id"
          exact
          component={CheckInDetails}
        />
        <PrivateRoute path="/okrs/levels-nicknames" exact component={LevelsNicknames} />
        <PrivateRoute path="/okrs/canvas" exact component={OkrsCanvas} />
        <PrivateRoute path="/okrs/:id" exact component={OkrDetails} />
        <PrivateRoute
          path="/okrs/:id/permissions"
          exact
          component={ManagementOkrPermissions}
        />
        <PrivateRoute path="/okr-evolution" exact component={OkrsEvolution} />
        <PrivateRoute path="/cycles" exact component={Cycles} />
        <PrivateRoute
          path="/cycles/:id/close-cycle"
          exact
          component={CloseCycle}
        />
        <PrivateRoute path="/registers/companies" exact component={RegisterCompany} />
        <PrivateRoute
          path="/registers/departments"
          component={RegisterDepartment}
          allowedRoles={["admin", "staff"]}
        />
        <PrivateRoute path="/registers/directorships" component={RegisterDirectroship} />
        <PrivateRoute path="/registers/teams" component={RegisterTeam} />
        {/* <PrivateRoute onlyAdmins={false} path='/okr-types' component={RegisterOkrType} /> */}
        <PrivateRoute
          path="/registers/users"
          exact
          component={RegisterUser}
          allowedRoles={["admin"]}
        />

        <PrivateRoute
          path="/settings/users"
          exact
          component={UserSettings}
          allowedRoles={["admin", "staff"]}
        />

        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute
          path="/reset-password-logged"
          component={ResetPassowrdLogged}
        />
        <PrivateRoute path="/flow" exact component={Flow} />
        <PrivateRoute path="/flow/boards/:id" exact component={FlowBoard} />
        <PrivateRoute
          path="/flow/boards/:boardId/invitations/:invitationId"
          component={BoardInvitation}
        />
        <PrivateRoute
          path="/management/companies"
          component={ManagementCompanies}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          path="/management/departments"
          component={ManagementDepartments}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          path="/management/users"
          component={ManagementUsers}
          allowedRoles={["admin"]}
        />
        <PrivateRoute
          path="/management/initiatives"
          component={ManagementInitiatives}
          allowedRoles={["admin"]}
        />
        <PrivateRoute path="/management/links" exact component={ManagementLinks} />
        <PrivateRoute
          path="/permissions/editing-okrs"
          component={ManagementOkrsPermissions}
        />
        {/* <PrivateRoute
            path="/permissions/visualizing-okrs"
            component={VisualizingOkrsPermissions}
          /> */}
        <PrivateRoute
          path="/monitoring/initiatives"
          component={InitiativesMonitoring}
        />
        <PrivateRoute
          path="/companies/reports"
          exact
          component={CompanyReports}
          allowedRoles={["admin"]}
        />
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
      {/* </Suspense> */}
    </BrowserRouter>
  );
}
