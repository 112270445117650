import LoadingBackground from "../../LoadingBackground"

import { LoadingPageContainer } from "./styles"

export function LoadingPage() {
  return (
    <LoadingPageContainer>
      <h2>Carregando página...</h2>

      <LoadingBackground size={32} />
    </LoadingPageContainer>
  )
}