import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useMemo,
} from "react";
import { useStack } from "../utils/hooks/useStack";

const ModalContext = createContext(null);

export function ModalProvider({ children }) {
  const stack = useStack();

  const [isShown, setIsShown] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [onHide, setOnHide] = useState(() => () => null);

  const hideModal = useCallback(() => {
    setIsShown(false);
    stack.empty();
    onHide();
  }, [stack, onHide]);

  const showModal = useCallback((props) => {
    setHeaderText(props.headerText);
    setIsShown(true);
  }, []);

  const registerChildComponent = useCallback(
    (childComponent) => {
      stack.push(childComponent);
    },
    [stack]
  );

  const registerOnHideCallback = useCallback((callbackFunction) => {
    setOnHide(() => callbackFunction);
  }, []);

  const value = useMemo(
    () => ({
      isShown,
      headerText,
      children: stack.items,
      registerChildComponent,
      registerOnHideCallback,
      showModal,
      hideModal,
    }),
    [
      isShown,
      headerText,
      stack.items,
      registerChildComponent,
      registerOnHideCallback,
      showModal,
      hideModal,
    ]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}
