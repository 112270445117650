import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useMemo,
} from "react";

import searchItemById from "../utils/searchItemById";

const OkrsContext = createContext(null);

export function OkrsProvider({ children }) {
  const [okrsState, setOkrsState] = useState([]);
  const [okrsByCurrentUserState, setOkrsByCurrentUserState] = useState([]);
  const [openedOkrLevel, setOpenedOkrLevel] = useState(null);
  const [afterNewOkrCreated, setAfterNewOkrCreated] = useState(null);
  const [selectedOkrToClone, setSelectedOkrToClone] = useState(null);

  const onOkrInfoChange = useCallback(
    (okr) => {
      const _okrs = [...okrsState];

      const { index } = searchItemById(okr.id, _okrs);

      if (index > -1) {
        _okrs[index] = okr;
        setOkrsState(_okrs);
      }
    },
    [okrsState]
  );

  const onNewOkrCreated = useCallback(
    (newOkr) => {
      setOkrsByCurrentUserState((oldOkrs) => [...oldOkrs, newOkr]);
      if (afterNewOkrCreated) {
        afterNewOkrCreated();
      }
    },
    [afterNewOkrCreated]
  );

  const setAfterNewOkrCreatedFunction = useCallback(
    (afterNewOkrCreatedFunction) => {
      setAfterNewOkrCreated(() => afterNewOkrCreatedFunction);
    },
    []
  );

  const value = useMemo(
    () => ({
      okrs: okrsState,
      okrsByCurrentUser: okrsByCurrentUserState,
      openedOkrLevel,
      selectedOkrToClone,
      setOkrs: setOkrsState,
      setOkrsByCurrentUser: setOkrsByCurrentUserState,
      setOpenedOkrLevel,
      setSelectedOkrToClone,
      onOkrInfoChange,
      onNewOkrCreated,
      setAfterNewOkrCreatedFunction,
    }),
    [
      okrsState,
      okrsByCurrentUserState,
      openedOkrLevel,
      selectedOkrToClone,
      setOkrsState,
      setOkrsByCurrentUserState,
      setOpenedOkrLevel,
      setSelectedOkrToClone,
      onOkrInfoChange,
      onNewOkrCreated,
      setAfterNewOkrCreatedFunction,
    ]
  );

  return <OkrsContext.Provider value={value}>{children}</OkrsContext.Provider>;
}

export function useOkrs() {
  return useContext(OkrsContext);
}
