export function getCookie(name) {
  const cookie = document.cookie.split(';').find(c => c.trim().startsWith(name + '='));

  if (cookie) {
    const cookieValue = cookie.split(name + '=')[1];

    return cookieValue;
  }

  return null;
}

export function removeCookie(name, path) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path || '/'};`;
}