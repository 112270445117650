import styled, { keyframes } from "styled-components";
import { animated } from "react-spring";
import { fadeIn } from "react-animations";

export const Header = styled(animated.header)`
  width: 100%;
  /* min-height: 100px; */
  /* height: 100%; */
  background: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  animation: 0.8s ${keyframes`${fadeIn}`};

  div.header-main-content {
    display: flex;
    align-items: center;

    svg {
      margin-left: 12px;
    }

    strong.header-text {
      margin-left: 12px;
      font-size: 24px;
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }

  div.header-final-content {
    display: flex;
    align-items: center;

    button.theme-toggler {
      margin: 0 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      outline: 0;
      border: 0;
      border-radius: 6px;
      padding: 4px;
      transition: all 0.2s ease;
    }

    button.theme-toggler.light {
      color: ${({ theme }) => theme.colors.text.secundary};
      background: #cdcdcd;
    }

    button.theme-toggler.dark {
      color: ${({ theme }) => theme.colors.secundary};
      background: #222;
    }
  }
`;
