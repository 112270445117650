import React from "react";

import { ImageContainer } from "./styles";

import defaultUser from "../../assets/default-user.png";

export default function UserProfilePhoto({
  src,
  size = 50,
  hasNotificationNotSeen,
  ...rest
}) {
  return <ImageContainer src={src ? src : defaultUser} size={size} {...rest} />;
}
