import React from "react";
import { Route, Redirect } from "react-router-dom";

import Layout from "../Layout";

import { useAuth } from "../../contexts";

export default function PrivateRoute({
  allowedRoles = [],
  component: Component,
  ...rest
}) {
  const auth = useAuth();

  function verify() {
    if (allowedRoles.length > 0) {
      if (allowedRoles.includes(auth.user.role)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  return (
    auth && (
      <Route
        {...rest}
        render={(props) =>
          auth.isAuthenticated ? (
            verify() ? (
              <Layout>
                <Component {...props} />
              </Layout>
            ) : (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            )
          ) : (
            <Redirect to={{ pathname: "/sign-in", state: { from: props.location } }} />
          )
        }
      />
    )
  );
}
