import { Cookies } from "react-cookie"
import { useCallback, useState, useEffect } from "react"

import config from '../../config'

const cookies = new Cookies()

function usePersistedSate(key, initialValue, comparativeInitialValueKey = "") {
  const [state, setState] = useState(() => {
    const cookieState = cookies.get(key)

    if (cookieState) {
      let storagedProps = [];
      let stateProps = [];

      if (comparativeInitialValueKey) {
        storagedProps = Object.getOwnPropertyNames(
          cookieState[comparativeInitialValueKey]
        );
        stateProps = Object.getOwnPropertyNames(
          initialValue[comparativeInitialValueKey]
        );
      } else {
        storagedProps = Object.getOwnPropertyNames(cookieState);
        stateProps = Object.getOwnPropertyNames(initialValue);
      }

      if (storagedProps.length !== stateProps.length) {
        return initialValue;
      }

      return cookieState;
    }

    return initialValue;
  });

  const handleSetCookie = useCallback((cookie) => {
    cookies.set(
      cookie.key,
      cookie.value,
      cookie.options
    )
  }, [])

  useEffect(() => {
    const stringfiedState = JSON.stringify(state)

    const themeCookie = {
      key,
      value: stringfiedState,
      options: {
        path: '/',
        domain: config.cookieDomain
      }
    }

    handleSetCookie(themeCookie)
  }, [key, state, handleSetCookie]);

  return [state, setState];
}

export default usePersistedSate;
