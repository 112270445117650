import React, { forwardRef } from "react";

import { Container } from "./styles";

const Switch = ({ id, ...rest }, ref) => (
  <Container className="switch-container">
    <input ref={ref} id={id} type="checkbox" className="switch" {...rest} />
    <label className="switch-button" htmlFor={id} />
  </Container>
);

export default forwardRef(Switch);
