import React from "react"

import { useNavbar } from "../../contexts"

import NavBar from "./NavBar"
import MainContent from "./MainContent"
import DefaultModal from "../DefaultModal"

import { Container } from "./styles"

function Layout({ children }) {
  const { hidden } = useNavbar()

  return (
    <Container>
      <NavBar className={`nav-bar-menu ${hidden ? "hidden" : ""}`} />
      <MainContent className="main-content-container">
        {children}
      </MainContent>

      <DefaultModal />
      {/* <PopOver /> */}
    </Container>
  )
}

export default Layout
