import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  aside.nav-bar-menu.hidden {
    div.nav-bar-end div.close-side-bar-box div.switch-container {
      display: none;
    }

    + main.main-content-container {
      border-radius: 0;
      box-shadow: none;
    }
  }

  @media (max-width: 1300px) {
    position: relative;
  }

  @media print {
    overflow: visible;
  }
`;
