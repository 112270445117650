import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  createContext,
} from "react";

import api from "../services/api";

import usePersistedState from "../utils/hooks/usePersistedState";
import useAuth from "./AuthContext";

const HeaderContext = createContext(null);

export function HeaderProvider({ children }) {
  const [icon, setIcon] = useState(() => null);
  const [text, setText] = useState("");
  const [marginBottom, setMarginBottom] = useState(20);
  const [notificationsNotSeen, setNotificationsNotSeen] = useState(0);

  const [hidden, setHidden] = usePersistedState("@Nimble_header_hidden", false);

  const { user } = useAuth();

  const handleSetHeader = useCallback(
    (headerIcon, headerText, headerMarginBottom = 20) => {
      setIcon(() => headerIcon);
      setText(headerText);
      setMarginBottom(headerMarginBottom);
    },
    []
  );

  const handleSetHeaderVisibility = useCallback(
    (hide) => {
      setHidden(hide);
    },
    [setHidden]
  );

  const onNotificationHasSeen = useCallback(() => {
    setNotificationsNotSeen(
      (oldNotificationsNotSeen) => oldNotificationsNotSeen - 1
    );
  }, []);

  const value = useMemo(
    () => ({
      icon,
      text,
      marginBottom,
      hidden,
      notificationsNotSeen,
      onNotificationHasSeen,
      handleSetHeader,
      handleSetHeaderVisibility,
    }),
    [
      icon,
      text,
      marginBottom,
      hidden,
      notificationsNotSeen,
      onNotificationHasSeen,
      handleSetHeader,
      handleSetHeaderVisibility,
    ]
  );

  useEffect(() => {
    async function loadNotificationsNotSeen() {
      const { data } = await api.get("/notifications/not-seen");

      setNotificationsNotSeen(data);
    }

    if (user) {
      loadNotificationsNotSeen();
    }
  }, [user]);

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
}

export function useHeader() {
  return useContext(HeaderContext);
}
