import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  createContext,
} from "react";

const KeyResultGoalsContext = createContext(null);

export function KeyResultGoalsProvider({ children }) {
  const [goalData, setGoalData] = useState(null);

  const handleSetGoalData = useCallback((value) => {
    setGoalData(value);
  }, []);

  const value = useMemo(
    () => ({
      goalData,
      handleSetGoalData,
    }),
    [goalData, handleSetGoalData]
  );

  return (
    <KeyResultGoalsContext.Provider value={value}>
      {children}
    </KeyResultGoalsContext.Provider>
  );
}

export function useKeyResultGoals() {
  return useContext(KeyResultGoalsContext);
}
