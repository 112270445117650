const NavBarLinkPathnameEnum = Object.freeze({
  Home: "/home",
  OkrsList: "/okrs",
  OkrsDetails: (pathname) => pathname.includes("/okrs/"),
  Cycles: "/cycles",
  OkrCanvas: "/okrs/canvas",
  AreaSwot: "/areas/swots",
  Monitoring: "/okrs/monitoring",
  CheckIn: (pathname) => pathname.includes("/okrs/checkins"),
  LevelsNicknames: "/okrs/levels-nicknames",
  Evolution: "/okr-evolution",
  RegisterUser: "/registers/users",
  RegisterCompany: "/registers/companies",
  RegisterDepartment: "/registers/departments",
  RegisterDirectorship: "/registers/directorships",
  RegisterTeam: "/registers/teams",
  UserSettings: "/settings/users",
  AreaSettings: "/settings/areas",
  ManagementUsers: "/management/users",
  ManagementCompanies: "/management/companies",
  ManagementDepartments: "/management/departments",
  ManagementInitiatives: "/management/initiatives",
  ManagementLinks: "/management/links",
  EditOkrPermissions: "/permissions/editing-okrs",
  VisualizeOkrPermissions: "/permissions/visualizing-okrs",
  Flow: "/flow",
  FlowBoard: (pathname) => pathname.includes("/flow/boards/"),
  CompaniesReports: "/companies/reports",
});

export default NavBarLinkPathnameEnum;
