const dark = {
  title: 'dark',

  colors: {
    primary: {
      dark: '#003afd',
      light: '#00ffff',
    },
    lightenPrimary: '#c3bdff',
    secundary: '#5C36BF',
    success: '#3bc14a',
    danger: '#E5383B',
    gray: {
      light: '#E6E8EB',
      medium: '#929493',
      dark: '#444',
    },

    background: {
      primary: '#333',
      secundary: '#232323',
    },

    text: {
      primary: '#FFF',
      secundary: '#F7F8FA',
    },
  }
};

export default dark;
