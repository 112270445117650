import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: ${({ isShown }) => (isShown ? 3 : -1)};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  transition: opacity 0.2s ease;
`;

export const ModalContent = styled.div`
  width: 768px;
  background: ${({ theme }) => theme.colors.background.secundary};
  border-radius: 4px;
  padding: 12px;
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  transition: opacity 0.3s ease;

  header.default-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    h3.header-text {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.text.secundary};
    }

    button.close-modal-button {
      border: 0;
      outline: 0;
      background: 0;
      cursor: pointer;
      border-radius: 50%;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.text.secundary};
      transition: background 0.2s ease;

      :hover {
        background: ${({ theme }) => theme.colors.background.primary};
      }
    }
  }

  div.default-modal-content-body {
    margin-top: 5px;
  }
`;
