import React, { useContext, useCallback, useMemo, createContext } from "react";

import usePersistedState from "../utils/hooks/usePersistedState";

const NavbarContext = createContext(null);

export function NavbarProvider({ children }) {
  const [hidden, setHidden] = usePersistedState("@Nimble_menu_hidden", false);

  const handleSetNavbarVisibility = useCallback(
    (hide) => {
      setHidden(hide);
    },
    [setHidden]
  );

  const value = useMemo(() => ({ hidden, handleSetNavbarVisibility }), [
    hidden,
    handleSetNavbarVisibility,
  ]);

  return (
    <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>
  );
}

export function useNavbar() {
  return useContext(NavbarContext);
}
