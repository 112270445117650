import React, { useCallback, useContext, memo } from "react";
import { ThemeContext } from "styled-components";
import { BsMoon } from "react-icons/bs";
import { useSpring } from "react-spring";

import { Header } from "./styles";

import { useHeader, useTheme } from "../../../contexts";

import UserBox from "../UserBox";

function PageHeader() {
  const { icon, text, marginBottom, hidden } = useHeader();
  const { toggleTheme } = useTheme();

  const { title } = useContext(ThemeContext);

  const handleToggleTheme = useCallback(toggleTheme, [toggleTheme]);

  const headerStyle = useSpring({
    minHeight: hidden ? 0 : 100,
    marginBottom,
  });

  return (
    <Header style={headerStyle} className="page-header-container">
      {!hidden && (
        <>
          <div className="header-main-content">
            {icon && icon(30)}
            <strong className="header-text">{text}</strong>
          </div>
          <div className="header-final-content">
            <UserBox />
            <button
              className={`theme-toggler ${title}`}
              type="button"
              onClick={handleToggleTheme}
            >
              <BsMoon size={30} />
            </button>
          </div>
        </>
      )}
    </Header>
  );
}

export default memo(PageHeader);
