import CompanyRoles from "../utils/CompanyRoles";

export const grantAdminAccess = (user) => user.role === "admin";
export const grantStaffAccess = (user) => user.role === "staff";
export const grantBasicAccess = (user) => user.role === "basic";
export const grantAdminAndStaffAccess = (user) =>
  user.role === "admin" || user.role === "staff";
export const grantStaffAndBasicAccess = (user) =>
  user.role === "staff" || user.role === "basic";
export const grantPrincipalAccess = (user) =>
  user.companyRole === CompanyRoles.Principal;
export const grantManagerAccess = (user) =>
  user.companyRole === CompanyRoles.Manager;
export const grantLeaderAccess = (user) =>
  user.companyRole === CompanyRoles.Leader;
