import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  createContext,
} from "react";

const ScrollableContainerContext = createContext(null);

export function ScrollableContainerProvider({ children }) {
  const [scrollableContainerRef, setScrollableContainerRef] = useState(null);

  const handleSetOnScroll = useCallback(
    (onScrollFunction) => {
      if (scrollableContainerRef) {
        scrollableContainerRef.addEventListener(
          "scroll",
          () => onScrollFunction(scrollableContainerRef),
          true
        );
      }
    },
    [scrollableContainerRef]
  );

  const value = useMemo(
    () => ({ handleSetOnScroll, setScrollableContainerRef }),
    [handleSetOnScroll, setScrollableContainerRef]
  );

  return (
    <ScrollableContainerContext.Provider value={value}>
      {children}
    </ScrollableContainerContext.Provider>
  );
}

export function useScrollableContainer() {
  return useContext(ScrollableContainerContext);
}
