import styled from "styled-components";
import { animated } from "react-spring";

export const Container = styled.main`
  flex: 5;
  height: 100%;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: ${({ theme }) => theme.colors.background.secundary};
  overflow: hidden;
  position: relative;
  transition: all 0.2 ease;

  @media (max-width: 1300px) {
    border-radius: 0;
    box-shadow: none;
  }

  @media print {
    min-height: 100%;
    overflow: visible;
  }
`;

export const ScrollableContainer = styled.div`
  height: 100%;
  max-height: ${({ headerHidden }) =>
    headerHidden ? "calc(100vh)" : "calc(100vh - 100px)"};
  padding-bottom: 20px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  /* 
  @media (max-height: 768px) {
    max-height: 80vh;
  } */

  @media print {
    overflow-y: visible;
  }
`;

export const ShowMenuBox = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 32px;
  left: 2px;

  button.open-box {
    width: 34px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.show-menu {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 6px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  button {
    height: 34px;
    border: 0;
    background: 0;
    outline: 0;
    cursor: pointer;
    background: #ccc;
    color: #555;
    display: flex;
    align-items: center;
    transition: filter 0.2s;

    :hover {
      filter: brightness(90%);
    }
  }

  @media print {
    display: none;
  }
`;
