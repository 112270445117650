import React, {
  useState,
  useEffect,
  useCallback,
  // useContext,
  useRef,
} from "react";
// import { ThemeContext } from 'styled-components';
import { Link, useLocation } from "react-router-dom";

import { useSpring } from "react-spring";
import {
  FiHome,
  FiList,
  FiTrendingUp,
  FiMoreVertical,
  FiLayout,
  FiSliders,
  FiLock,
  FiBookOpen,
} from "react-icons/fi";

import { Container } from "./styles";
import {
  grantAdminAccess,
  grantAdminAndStaffAccess,
  grantPrincipalAccess,
  grantLeaderAccess,
  grantManagerAccess,
} from "../../../services/grantAccess";
import NavBarLinkPathnameEnum from "../../../utils/NavBarLinkPathnameEnum";
import { useAuth, useNavbar, useHeader } from "../../../contexts";
// import logoNimble from "../../../assets/logo-nimble.png";
import sideBarImage from "../../../assets/nimble-e2b-blue.png";

import Modal from "../../Modal";
import Switch from "../../Switch";
import NavBarDropdown from "../NavBarDropdown";

export default function NavBar({ className }) {
  const [helpModal, setHelpModal] = useState(false);
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  // const { toggleTheme } = useTheme();
  // const { title } = useContext(ThemeContext);

  const { hidden: navbarHidden, handleSetNavbarVisibility } = useNavbar();
  const { hidden: headerHidden, handleSetHeaderVisibility } = useHeader();
  const auth = useAuth();
  const location = useLocation();

  const toggleSwitchRef = useRef(null);
  const toggleHeaderRef = useRef(null);

  const fade = useSpring({
    maxWidth: navbarHidden ? 0 : 400,
    padding: navbarHidden ? 0 : 22,
  });

  function getOkrEvolutionPageTab() {
    return auth.user.typesCanSee[0] && `?tab=${auth.user.typesCanSee[0].id}`;
  }

  function handleTouchEnd() {
    if (touchEnd > 0 && touchStart - touchEnd > 75 && !navbarHidden) {
      handleSetNavbarVisibility(true)
      setTouchStart(0)
      setTouchEnd(0)
    }
  }

  const handleWindowResize = useCallback(() => {
    const width = document.documentElement.clientWidth;

    if (width > 700 && toggleSideBar) setToggleSideBar(false);
  }, [toggleSideBar]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
  }, [handleWindowResize]);

  useEffect(() => {
    if (toggleSwitchRef.current) {
      toggleSwitchRef.current.checked = navbarHidden;
    }
  }, [toggleSwitchRef, navbarHidden]);

  useEffect(() => {
    if (toggleHeaderRef.current) {
      toggleHeaderRef.current.checked = headerHidden;
    }
  }, [toggleHeaderRef, headerHidden]);

  return (
    <Container
      style={fade}
      className={className}
      onTouchStart={e => setTouchStart(e.targetTouches[0].clientX)}
      onTouchMove={e => setTouchEnd(e.targetTouches[0].clientX)}
      onTouchEnd={handleTouchEnd}
    >
      <div className="nav-bar-start">
        <div className="brand-box">
          <img src={sideBarImage} alt="E2B Agile" />
        </div>

        <ul className="nav-bar-items">
          <li>
            <a
              href="/"
              className={
                NavBarLinkPathnameEnum.Home === location.pathname
                  ? "nav-link active-nav-link"
                  : "nav-link"
              }
            >
              <FiHome size={34} />
              Home
            </a>
          </li>
          <NavBarDropdown
            active={
              NavBarLinkPathnameEnum.OkrsList === location.pathname ||
              NavBarLinkPathnameEnum.Cycles === location.pathname ||
              NavBarLinkPathnameEnum.OkrsDetails(location.pathname)
            }
            togglerComponent={() => (
              <>
                <FiList size={34} />
                OKRs
              </>
            )}
          >
            <a
              href="/okrs"
              className={
                NavBarLinkPathnameEnum.OkrsList === location.pathname
                  ? "nav-link active-nav-link"
                  : "nav-link"
              }
            >
              Lista
            </a>
            {/* <Link
              to="/okrs"
              className={
                NavBarLinkPathnameEnum.OkrsList === location.pathname
                  ? "nav-link active-nav-link"
                  : "nav-link"
              }
            >
              
            </Link> */}
            <Link
              to="/cycles"
              className={
                NavBarLinkPathnameEnum.Cycles === location.pathname
                  ? "nav-link active-nav-link"
                  : "nav-link"
              }
            >
              Ciclos
            </Link>

            <a
              href="/okrs/canvas/areas"
              className={
                NavBarLinkPathnameEnum.OkrCanvas === location.pathname
                  ? "nav-link active-nav-link"
                  : "nav-link"
              }
            >
              Canvas Setorial
            </a>

            <a
              href="/areas/swots"
              className={
                NavBarLinkPathnameEnum.AreaSwot === location.pathname
                  ? "nav-link active-nav-link"
                  : "nav-link"
              }
            >
              SWOT de Área
            </a>

            {(auth.user.canSeeMonitoringScreen ||
              grantAdminAndStaffAccess(auth.user)) && (
                <Link
                  to="/okrs/monitoring"
                  className={
                    NavBarLinkPathnameEnum.Monitoring === location.pathname
                      ? "nav-link active-nav-link"
                      : "nav-link"
                  }
                >
                  Monitoramento
                </Link>
              )}

            {(grantAdminAndStaffAccess(auth.user) || auth.user.canDoCheckins) && (
              <Link
                to="/okrs/checkins"
                className={
                  NavBarLinkPathnameEnum.CheckIn(location.pathname)
                    ? "nav-link active-nav-link"
                    : "nav-link"
                }
              >
                Check-In
              </Link>
            )}

            {grantAdminAndStaffAccess(auth.user) && (
              <Link
                to="/okrs/levels-nicknames"
                className={
                  NavBarLinkPathnameEnum.LevelsNicknames === location.pathname
                    ? "nav-link active-nav-link"
                    : "nav-link"
                }
              >
                Apelidos de níveis
              </Link>
            )}
          </NavBarDropdown>
          <li>
            <Link
              to={`/okr-evolution${getOkrEvolutionPageTab()}`}
              className={
                NavBarLinkPathnameEnum.Evolution === location.pathname
                  ? "nav-link active-nav-link"
                  : "nav-link"
              }
            >
              <FiTrendingUp size={34} />
              Evolução
            </Link>
          </li>
          {grantAdminAndStaffAccess(auth.user) && (
            <NavBarDropdown
              active={
                NavBarLinkPathnameEnum.RegisterUser === location.pathname ||
                NavBarLinkPathnameEnum.RegisterCompany === location.pathname ||
                NavBarLinkPathnameEnum.RegisterDepartment ===
                location.pathname ||
                NavBarLinkPathnameEnum.RegisterDirectorship ===
                location.pathname ||
                NavBarLinkPathnameEnum.RegisterTeam === location.pathname
              }
              togglerComponent={() => (
                <>
                  <FiMoreVertical size={34} />
                  Cadastros
                </>
              )}
            >
              {grantAdminAccess(auth.user) && (
                <Link
                  to="/registers/companies"
                  className={
                    NavBarLinkPathnameEnum.RegisterCompany === location.pathname
                      ? "nav-link active-nav-link"
                      : "nav-link"
                  }
                >
                  Empresa
                </Link>
              )}
              {(grantAdminAndStaffAccess(auth.user) ||
                grantPrincipalAccess(auth.user)) && (
                  <Link
                    to="/registers/directorships"
                    className={
                      NavBarLinkPathnameEnum.RegisterDirectorship ===
                        location.pathname
                        ? "nav-link active-nav-link"
                        : "nav-link"
                    }
                  >
                    Diretoria
                  </Link>
                )}
              {(grantAdminAndStaffAccess(auth.user) ||
                grantManagerAccess(auth.user)) && (
                  <Link
                    to="/registers/departments"
                    className={
                      NavBarLinkPathnameEnum.RegisterDepartment ===
                        location.pathname
                        ? "nav-link active-nav-link"
                        : "nav-link"
                    }
                  >
                    Setor
                  </Link>
                )}
              {(grantAdminAndStaffAccess(auth.user) ||
                grantLeaderAccess(auth.user)) && (
                  <Link
                    to="/registers/teams"
                    className={
                      NavBarLinkPathnameEnum.RegisterTeam === location.pathname
                        ? "nav-link active-nav-link"
                        : "nav-link"
                    }
                  >
                    Equipe
                  </Link>
                )}
              {grantAdminAccess(auth.user) && (
                <Link
                  to="/registers/users"
                  className={
                    NavBarLinkPathnameEnum.RegisterUser === location.pathname
                      ? "nav-link active-nav-link"
                      : "nav-link"
                  }
                >
                  Usuário
                </Link>
              )}
            </NavBarDropdown>
          )}

          {
            auth.user.role !== 'basic' && (
              <NavBarDropdown
                active={
                  NavBarLinkPathnameEnum.UserSettings === location.pathname
                  || NavBarLinkPathnameEnum.AreaSettings === location.pathname
                }
                togglerComponent={() => (
                  <>
                    <FiSliders size={34} />
                    Ajustes
                  </>
                )}
              >
                <Link
                  to="/settings/users"
                  className={
                    NavBarLinkPathnameEnum.UserSettings === location.pathname
                      ? "nav-link active-nav-link"
                      : "nav-link"
                  }
                >Usuários</Link>

                <a
                  href="/settings/areas"
                  className={
                    NavBarLinkPathnameEnum.AreaSettings === location.pathname
                      ? "nav-link active-nav-link"
                      : "nav-link"
                  }
                >Areas</a>
              </NavBarDropdown>
            )
          }

          {grantAdminAccess(auth.user) && (
            <NavBarDropdown
              active={
                NavBarLinkPathnameEnum.ManagementLinks === location.pathname ||
                NavBarLinkPathnameEnum.ManagementUsers === location.pathname ||
                NavBarLinkPathnameEnum.ManagementCompanies ===
                location.pathname ||
                NavBarLinkPathnameEnum.ManagementDepartments ===
                location.pathname ||
                NavBarLinkPathnameEnum.ManagementInitiatives ===
                location.pathname
              }
              togglerComponent={() => (
                <>
                  <FiSliders size={34} />
                  Gerenciamento
                </>
              )}
            >
              <Link
                to="/management/companies"
                className={
                  NavBarLinkPathnameEnum.ManagementCompanies ===
                    location.pathname
                    ? "nav-link active-nav-link"
                    : "nav-link"
                }
              >
                Empresas
              </Link>
              <Link
                to="/management/departments"
                className={
                  NavBarLinkPathnameEnum.ManagementDepartments ===
                    location.pathname
                    ? "nav-link active-nav-link"
                    : "nav-link"
                }
              >
                Setores
              </Link>
              <Link
                to="/management/users"
                className={
                  NavBarLinkPathnameEnum.ManagementUsers === location.pathname
                    ? "nav-link active-nav-link"
                    : "nav-link"
                }
              >
                Usuários
              </Link>
              <Link
                to="/management/initiatives"
                className={
                  NavBarLinkPathnameEnum.ManagementInitiatives ===
                    location.pathname
                    ? "nav-link active-nav-link"
                    : "nav-link"
                }
              >
                Iniciativas
              </Link>
              <Link
                to="/management/links?tab=levels-configuration"
                className={
                  NavBarLinkPathnameEnum.ManagementLinks === location.pathname
                    ? "nav-link active-nav-link"
                    : "nav-link"
                }
              >
                Vínculos
              </Link>
            </NavBarDropdown>
          )}

          <NavBarDropdown
            active={location.pathname.includes('/reports')}
            togglerComponent={() => (
              <>
                <FiBookOpen size={34} />
                Relatórios
              </>
            )}
          >
            <a className="nav-link" href="/reports/okrs">
              OKRs
            </a>
            {grantAdminAndStaffAccess(auth.user) && (
              <>
                <a className="nav-link" href="/reports/area-canvases">
                  Canvas Setorial
                </a>
                <a className="nav-link" href="/reports/swot">
                  SWOT
                </a>
                <a className="nav-link" href="/reports/score">
                  Score
                </a>
              </>
            )}
          </NavBarDropdown>

          {
            !auth.user.onlyVisualize && (
              <NavBarDropdown
                active={
                  NavBarLinkPathnameEnum.EditOkrPermissions === location.pathname ||
                  NavBarLinkPathnameEnum.VisualizeOkrPermissions ===
                  location.pathname
                }
                togglerComponent={() => (
                  <>
                    <FiLock size={34} />
                    Permissões
                  </>
                )}
              >
                <Link
                  to="/permissions/editing-okrs"
                  className={
                    NavBarLinkPathnameEnum.EditOkrPermissions === location.pathname
                      ? "nav-link active-nav-link"
                      : "nav-link"
                  }
                >
                  Edição de OKRs
                </Link>
                {/* <Link
                  to="/permissions/visualizing-okrs"
                  className={
                    NavBarLinkPathnameEnum.VisualizeOkrPermissions ===
                      location.pathname
                      ? 'nav-link active-nav-link'
                      : 'nav-link'
                  }
                >
                  Visualização de Okrs
                  </Link> */}
              </NavBarDropdown>
            )
          }
          <li>
            <Link
              to="/flow"
              className={
                NavBarLinkPathnameEnum.Flow === location.pathname ||
                  NavBarLinkPathnameEnum.FlowBoard(location.pathname)
                  ? "nav-link active-nav-link"
                  : "nav-link"
              }
            >
              <FiLayout size={34} />
              Flow
            </Link>
          </li>
        </ul>
      </div>

      <div className="nav-bar-end">
        <div className="separator" />

        <div className="close-side-bar-box">
          <Switch
            id="sideBarSwitch"
            ref={toggleSwitchRef}
            onChange={() =>
              handleSetNavbarVisibility(toggleSwitchRef.current.checked)
            }
          />
          <span>Esconder menu (Alt + M)</span>
        </div>

        <div className="close-header-box">
          <Switch
            id="headerSwitch"
            ref={toggleHeaderRef}
            onChange={() =>
              handleSetHeaderVisibility(toggleHeaderRef.current.checked)
            }
          />
          <span>Esconder cabeçalho (Alt + C)</span>
        </div>
      </div>

      <Modal
        isOpen={helpModal}
        onClose={() => setHelpModal(false)}
        title="Ajuda dos Okrs"
      >
        <div className="help-modal-content">
          <p>
            <strong>Objetivo:</strong> Aonde eu quero chegar?
          </p>
          <p>
            <strong>Resultados Chave (Key Results):</strong> Como eu vou saber
            se estou chegando lá?
          </p>
        </div>
      </Modal>
    </Container>
  );
}
