import styled from "styled-components"

export const LoadingPageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.background.secundary};

  > h2 {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`