import React from "react";

import { StyledIconButton } from "./styles";

export default function IconButton({
  children,
  onClick,
  className = "",
  id,
  withHover = false,
  height = 36,
  color = "black",
  fontWeight = "bold",
  ...rest
}) {
  return (
    <StyledIconButton
      style={{ height, color, fontWeight }}
      type="button"
      onClick={onClick}
      className={`${className} icon-button`}
      id={id}
      withHover={withHover}
      {...rest}
    >
      {children}
    </StyledIconButton>
  );
}
