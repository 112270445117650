import React, { useState, useCallback, useRef, useEffect, Suspense } from "react";
import { useSpring, animated } from "react-spring";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import {
  useNavbar,
  useScrollableContainer,
  useHeader,
} from "../../../contexts";

import PageHeader from "../PageHeader";
import { LoadingPage } from "../LoadingPage";

import { Container, ShowMenuBox, ScrollableContainer } from "./styles";

function MainContent({ className, children }) {
  const [toggle, setToggle] = useState(false);
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const { hidden: navbarHidden, handleSetNavbarVisibility } = useNavbar();
  const { setScrollableContainerRef } = useScrollableContainer();
  const { hidden: headerHidden, handleSetHeaderVisibility } = useHeader();

  const fadeBox = useSpring({
    maxWidth: toggle ? 220 : 34,
  });
  const fadeButton = useSpring({
    maxWidth: toggle ? 186 : 0,
    opacity: toggle ? 1 : 0,
  });

  const scrollableContainerRef = useRef(null);

  const handleOpenMenu = useCallback(() => {
    handleSetNavbarVisibility(false);
    setToggle(false);
  }, [handleSetNavbarVisibility]);

  const handleWindowKeyDown = useCallback(
    (e) => {
      if (e.altKey && e.keyCode === 77) {
        handleSetNavbarVisibility(!navbarHidden);
      }

      if (e.altKey && e.keyCode === 67) {
        handleSetHeaderVisibility(!headerHidden);
      }
    },
    [
      handleSetNavbarVisibility,
      navbarHidden,
      handleSetHeaderVisibility,
      headerHidden,
    ]
  );

  function handleTouchEnd() {
    if (touchEnd > 0 && touchStart - touchEnd < 75 && navbarHidden) {
      setTouchStart(0)
      setTouchEnd(0)

      handleOpenMenu()
    }
  }

  useEffect(() => {
    if (scrollableContainerRef.current) {
      setScrollableContainerRef(scrollableContainerRef.current);
    }
  }, [scrollableContainerRef, setScrollableContainerRef]);

  window.onkeydown = handleWindowKeyDown;

  return (
    <Container className={className}>
      <Suspense fallback={<LoadingPage />}>
        <>
          <PageHeader />
          <ScrollableContainer
            ref={scrollableContainerRef}
            className="scrollable-container"
            headerHidden={headerHidden}
          >
            {children}
          </ScrollableContainer>
          {
            // alterar para apenas um botão ao inves de uma div
            navbarHidden && (
              <ShowMenuBox
                style={fadeBox}
                onTouchStart={e => setTouchStart(e.targetTouches[0].clientX)}
                onTouchMove={e => setTouchEnd(e.targetTouches[0].clientX)}
                onTouchEnd={handleTouchEnd}
              >
                {toggle && (
                  <animated.button
                    style={fadeButton}
                    className="show-menu"
                    onClick={handleOpenMenu}
                  >
                    Mostrar menu
                  </animated.button>
                )}
                <button
                  type="button"
                  className="open-box"
                  onClick={() => setToggle(!toggle)}
                >
                  {toggle ? (
                    <FiChevronLeft size={22} />
                  ) : (
                    <FiChevronRight size={22} />
                  )}
                </button>
              </ShowMenuBox>
            )
          }
        </>
      </Suspense>
    </Container>
  );
}

export default MainContent;
