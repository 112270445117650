import { useState, useCallback, useMemo } from "react";

export function useStack() {
  const [stack, setStack] = useState([]);

  const push = useCallback(
    (item) => {
      setStack([...stack, item]);
    },
    [stack]
  );

  const pop = useCallback(() => {
    const _stack = [...stack];
    _stack.pop();
    setStack(_stack);
  }, [stack]);

  const empty = useCallback(() => {
    setStack([]);
  }, []);

  const top = useCallback(() => {
    if (stack.length > 0) {
      return stack[stack.length - 1];
    }

    return null;
  }, [stack]);

  const memoizedStack = useMemo(
    () => ({
      items: stack,
      push,
      pop,
      empty,
      top,
    }),
    [stack, push, pop, empty, top]
  );

  return memoizedStack;
}
