import React, {
  useState,
  useContext,
  useEffect,
  createContext,
  useCallback,
  useMemo,
} from "react";
import { useCookies } from "react-cookie";

import api from "../services/api";
import CompanyRoles from "../utils/CompanyRoles";
import config from "../config";

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  token: "",
  doLogin: (token, user) => ({ user, token }),
  doLogout: () => null,
});

export function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    token: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const [cookies, setCookies, removeCookie] = useCookies();

  const doLogin = useCallback(async (token, user) => {
    const { accessToken, expiresIn } = token;

    const expiresDate = new Date(expiresIn);

    setCookies('@nimble@e2b@token', accessToken, { path: '/', expires: expiresDate, domain: config.cookieDomain });

    if (user && user.companyRole === CompanyRoles.Manager) {
      const { data: managerCanCreateCompanyOkrs } = await api.get(
        `/management/users/${user.id}/can-create-company-okrs`
      );
      user["managerCanCreateCompanyOkrs"] =
        managerCanCreateCompanyOkrs.canCreate
    }

    setAuthState({
      isAuthenticated: true,
      user,
      token,
    });
  }, [setCookies]);

  const doLogout = useCallback(() => {
    removeCookie('@nimble@e2b@token', { path: '/', domain: config.cookieDomain });
    setAuthState({
      isAuthenticated: false,
      user: null,
      token: "",
    });

    // window.location.reload()
  }, [removeCookie]);

  const setUser = useCallback((user) => {
    setAuthState((oldAuthState) => ({
      ...oldAuthState,
      user,
    }));
  }, []);

  const value = useMemo(
    () => ({ ...authState, isLoading, doLogin, doLogout, setUser }),
    [authState, isLoading, doLogin, doLogout, setUser]
  );

  useEffect(() => {
    async function loadLoggedUser() {
      setIsLoading(true);
      const nimbleToken = cookies['@nimble@e2b@token'];

      if (nimbleToken) {
        const { data: userData } = await api.get('/sessions');

        if (userData && userData.error && userData.errorId === 1) {
          alert("Usuário não encontrado!");
          doLogout();
        } else {
          if (userData && userData.companyRole === CompanyRoles.Manager) {
            const { data: managerCanCreateCompanyOkrs } = await api.get(
              `/management/users/${userData.id}/can-create-company-okrs`
            );
            userData["managerCanCreateCompanyOkrs"] =
              managerCanCreateCompanyOkrs.canCreate;
          }

          setAuthState({
            isAuthenticated: true,
            user: userData,
            token: nimbleToken,
          });
        }
      }
      setIsLoading(false);
    }

    loadLoggedUser();
  }, [doLogout, cookies]);

  return (
    !isLoading && (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
