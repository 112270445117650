import React, { useEffect, useState, useRef, useCallback } from "react";
import { FiX } from "react-icons/fi";

import { Container, Title } from "./styles";

import IconButton from "../IconButton";
import { ForwardedRefInput } from "../Input/ThemeDefault";

export default function Modal({
  children,
  isOpen,
  onClose,
  width,
  title,
  editableTitle = false,
  onTitleEdited,
}) {
  const [modal, setModal] = useState(false);
  const [editTitle, setEditTitle] = useState(false);

  const modalRef = useRef(null);
  const titleRef = useRef(null);

  function onTitleKeyUp(e) {
    if (e.key === "Escape") {
      setEditTitle(false);
    }
    if (e.key === "Enter") {
      setEditTitle(false);
      if (onTitleEdited) {
        onTitleEdited(titleRef.current.value);
      }
    }
  }

  function onTitleBlur() {
    setEditTitle(false);
    if (onTitleEdited) {
      onTitleEdited(titleRef.current.value);
    }
  }

  const handleCloseModal = useCallback(() => {
    setModal(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    setModal(isOpen);
    if (isOpen) document.getElementById("root").style.overflow = "hidden";
    else document.getElementById("root").style.overflow = "auto";
  }, [isOpen]);

  useEffect(() => {
    window.onclick = (e) => {
      if (e.target === modalRef.current) handleCloseModal();
    };

    if (modal) {
      window.onkeydown = (e) => {
        if (e.key === "Escape" && e.target.id === "nimbleBody") {
          handleCloseModal();
        }
      };
    }
  }, [handleCloseModal, modal]);

  useEffect(() => {
    if (editableTitle && editTitle && titleRef.current) {
      titleRef.current.setSelectionRange(0, titleRef.current.value.length);
      titleRef.current.focus();
    }
  }, [editableTitle, editTitle, titleRef]);

  return (
    <Container
      style={{
        display: modal ? "flex" : "none",
      }}
      className="default-modal"
      ref={modalRef}
      // e => e.key === 'Escape' ? handleCloseModal() : null
    >
      <div className="default-modal-body" style={{ width }}>
        <header className="default-modal-header">
          {editTitle ? (
            <ForwardedRefInput
              ref={titleRef}
              type="text"
              defaultValue={title}
              onKeyUp={onTitleKeyUp}
              onBlur={onTitleBlur}
            />
          ) : (
            <Title
              onClick={editableTitle ? () => setEditTitle(true) : () => null}
              editable={editableTitle}
            >
              {title}
            </Title>
          )}
          <IconButton onClick={handleCloseModal} withHover={true}>
            <FiX size={18} />
          </IconButton>
        </header>
        <div className="content">{modal && children}</div>
      </div>
    </Container>
  );
}
