import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSpring } from "react-spring";
import { FiBell, FiBookOpen, FiList, FiLogOut, FiUser, FiUsers } from "react-icons/fi";

import {
  Container,
  HasNotificationsNotSeenBadge,
  LogoutButton,
  PopupArrow,
  PopupMainContent,
  UserPopupBox,
} from "./styles";

import { useAuth, useHeader } from "../../../contexts";

import UserProfilePhoto from "../../UserProfilePhoto";

function UserBox() {
  const [openUserPopup, setOpenUserPopup] = useState(false);

  const { user, doLogout } = useAuth();
  const { notificationsNotSeen } = useHeader();

  const userPopupStyle = useSpring({
    display: openUserPopup ? "flex" : "none",
    opacity: openUserPopup ? 1 : 0,
  });

  return (
    <Container>
      <UserProfilePhoto
        src={user && user.profilePhotoUrl}
        className="user-profile-photo"
        onClick={() => setOpenUserPopup(!openUserPopup)}
      />

      {notificationsNotSeen > 0 && (
        <HasNotificationsNotSeenBadge
          size={0.75}
          calcTop="0.1rem"
          calcLeft="50px - 0.6rem"
        />
      )}

      <UserPopupBox style={userPopupStyle}>
        <PopupArrow />
        <PopupMainContent>
          <Link
            to="/profile?tab=informacoes"
            onClick={() => setOpenUserPopup(false)}
          >
            <FiUser size={20} />
            Perfil
          </Link>
          <Link to="/profile?tab=okrs" onClick={() => setOpenUserPopup(false)}>
            <FiList size={20} />
            Meus OKRs
          </Link>
          <Link
            to="/profile?tab=grupos"
            onClick={() => setOpenUserPopup(false)}
          >
            <FiUsers size={20} />
            Grupos
          </Link>
          <a
            href="/profile/area-avaliations"
            onClick={() => setOpenUserPopup(false)}
          >
            <FiBookOpen size={20} />
            Avaliações de Área
          </a>
          <Link
            to="/profile?tab=notificacoes"
            className={
              notificationsNotSeen > 0 ? "has-notifications" : undefined
            }
            onClick={() => setOpenUserPopup(false)}
          >
            <FiBell size={20} />
            Notificações
            {notificationsNotSeen > 0 && (
              <HasNotificationsNotSeenBadge
                size={0.5}
                calcTop="1rem"
                calcLeft="1rem + (20px - 0.5rem)"
              />
            )}
          </Link>

          <LogoutButton type="button" onClick={doLogout}>
            <FiLogOut size={20} />
            Sair
          </LogoutButton>
        </PopupMainContent>
      </UserPopupBox>
    </Container>
  );
}

export default UserBox;
