import React from "react";
import { FiX } from "react-icons/fi";

import { Container, ModalContent } from "./styles";
import { useModal } from "../../contexts";

function DeafultModal() {
  const { isShown, headerText, children, hideModal } = useModal();

  return (
    <Container isShown={isShown}>
      <ModalContent isShown={isShown}>
        <header className="default-modal-header">
          <h3 className="header-text">{headerText}</h3>
          <button
            type="button"
            className="close-modal-button"
            onClick={hideModal}
          >
            <FiX size={24} />
          </button>
        </header>
        <div className="default-modal-content-body">{children}</div>
      </ModalContent>
    </Container>
  );
}

export default DeafultModal;
